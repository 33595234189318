<template>
  <div>
    <b-overlay :show="$apollo.loading || mutationLoading">
      <b-card>
        <validation-observer
          ref="uploadForm"
          tag="form"
        >
          <b-row>
            <b-card-header
              class="justify-content-start py-0 px-1 pb-1 mb-1 border-bottom align-items-center"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="$router.back()"
              >
                <feather-icon
                  class="mr-25"
                  icon="ChevronLeftIcon"
                />
              </b-button>
              <h3 class="mb-0 ml-2 flex-grow-1">
                View Growth Version
              </h3>
            </b-card-header>
            <b-col cols="12">
              <b-form-group
                label="Title"
                label-size="sm"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <b-form-input
                    v-model="questiondata[0].title"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Title"
                    required
                    disabled
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Description"
                label-size="sm"
              >
                <b-form-textarea
                  v-model="questiondata[0].description"
                  placeholder="Description"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <h5><strong>Answer the Following Parameters</strong></h5>
            </b-col>
          </b-row>
          <b-row
            v-for="(question, index) in questiondata[0].growth_parameters_startup_entries"
            :key="index"
          >
            <b-col>
              <b-form-group
                :label="`Q. ${question.growth_parameter.title} ${question.growth_parameter.is_required ? '*' : ''}`"
                :label-class="`h5`"
                :label-for="`question-${index}`"
                class="mb-2"
              >
                <template #description>
                  <small
                    v-if="!readonly && question.growth_parameter.input_type === 'Document' && question.response "
                    class="font-weight-bold"
                  >File already uploaded. Uploading new file will replace existing file.</small>
                </template>
                <b-input-group class="mt-50">
                  <b-form-textarea
                    v-if="question.growth_parameter.input_type === 'Long text'"
                    :id="`answer-${index}`"
                    v-model="question.response"
                    :readonly="readonly"
                    placeholder="Your answer"
                    required
                  />
                  <v-select
                    v-else-if="question.growth_parameter.input_type === 'Dropdown' || question.growth_parameter.input_type === 'Multiselect'"
                    :id="`answer-${index}`"
                    v-model="question.response"
                    :style="readonly?'pointer-events:none;filter:grayscale(100%)':''"
                    :multiple="question.growth_parameter.input_type === 'Multiselect'"
                    :options="JSON.parse(question.growth_parameter.options)"
                    append-to-body
                    class="w-100"
                    label="Choose from the following"
                    placeholder="Select from list"
                  />
                  <b-form-file
                    v-else-if="readonly !== true && question.growth_parameter.input_type==='Document'"
                    :id="`answer-${index}`"
                    v-model="files[question.growth_parameter.id]"
                    drop-placeholder="Drop file here..."
                    placeholder="Choose a file or drop it here... [Max: 5MB]"
                    @input="checkFileSize(files[question.growth_parameter.id], question.growth_parameter.id)"
                  />
                  <b-form-input
                    v-else
                    :id="`answer-${index}`"
                    v-model="question.response"
                    :readonly="readonly"
                    :type="question.growth_parameter.input_type === 'Number' ? 'number' : 'text'"
                    placeholder="Your answer"
                    required
                  />
                  <b-input-group-append
                    v-if="question.growth_parameter.input_type==='Document' && question.response && !files[question.growth_parameter.id]"
                  >
                    <Promised
                      :promise="getLink(question.response)"
                    >
                      <template #pending>
                        <b-button
                          class="mr-50"
                          disabled
                          size="sm"
                          variant="primary"
                        >
                          <feather-icon icon="ClockIcon" />
                        </b-button>
                      </template>
                      <template #default="data">
                        <b-button
                          v-b-tooltip="'View Uploaded File'"
                          :href="data"
                          class="mr-50"
                          target="_blank"
                          variant="primary"
                        >Open
                        </b-button>
                      </template>
                      <template #rejected>
                        <span v-b-tooltip="`File not found`">
                          <b-button
                            class="mr-50"
                            disabled
                            size="sm"
                            variant="outline-danger"
                          ><feather-icon icon="AlertTriangleIcon" /></b-button>
                        </span>
                      </template>
                    </Promised>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-button
                variant="primary"
                @click="addNewVersion()"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </b-overlay>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import gql from 'graphql-tag'
import { Promised } from 'vue-promised'
import {
  BCard,
  BButton,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BCol,
  BCardHeader,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    vSelect,
    Promised,
    BFormTextarea,
    BCardHeader,
    BFormGroup,
    BFormInput,
    BCol,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      pageLength: 5,
      newload: true,
      selectedBd: null,
      questiondata: [],
      vbd: [],
      columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'Badge',
          field: 'badge',
        },
        {
          label: 'Date Added',
          field(row) {
            const dateNew = new Date(row.created_at)
            const options = { day: 'numeric', month: 'long', year: 'numeric' }
            const formattedDate = dateNew.toLocaleDateString('en-US', options)
            return formattedDate
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      title: null,
      description: '',
      readonly: true,
      file: null,
      selectedIndex: null,
      mutationLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      selectedOrg: 'user/getSelectedOrgDetails',
    }),
  },
  methods: {
    beginDate(date) {
      const dateNew = new Date(date)
      const options = { day: 'numeric', month: 'long', year: 'numeric' }
      const formattedDate = dateNew.toLocaleDateString('en-US', options)
      return formattedDate
    },
    getvbd() {
      this.$apollo.queries.vbd.refetch()
    },
    getuploadingdata() {
      const temp = []
      this.questiondata[0].growth_parameters.forEach(data => {
        temp.push({
          response: data.response,
          growth_parameters_id: data.id,
        })
      })
      this.questiondata[0].growth_custom_parameters.forEach(data => {
        temp.push({
          response: data.response,
          growth_custom_parameters_id: data.id,
        })
      })
      return temp
    },
    async addNewVersion() {
      // console.log(this.questiondata[0])
      this.mutationLoading = true
      const mutation = gql`
        mutation($data: [growth_parameters_startup_entry_insert_input!]!) {
          insert_growth_category_startup_entry_one (
            object: {
              startup_id: ${this.selectedOrg.orgId},
              title: ${this.title},
              description: ${this.description},
              growth_categories_id: ${this.selectedBd},
              growth_parameters_startup_entries:{
                data: $data
              }
            }
          ) {
            id
          }
        }
      `
      this.$apollo.mutate({
        mutation,
        variables: {
          data: this.getuploadingdata(),
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Successfully added new version.',
            icon: 'CompassIcon',
            variant: 'success',
          },
        })
        this.$router.back()
        this.mutationLoading = false
      }).catch(error => {
        // console.log(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong. Please try again later.',
            message: error.message,
            icon: 'CompassIcon',
            variant: 'danger',
          },
        })
        this.mutationLoading = false
      })
    },
    deleteversion() {
      this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete this version?',
        {
          okTitle: 'No, Thanks',
          cancelTitle: 'Yes, Delete',
          title: 'Delete Version',
        },
      )
        .then(value => {
          if (value === false) {
            this.mutationLoading = true
            this.$apollo.mutate(
              {
                mutation: gql`mutation MyMutation {
                      update_versions_business_details_by_pk(pk_columns: {id: ${this.selectedIndex}}, _set: {is_deleted: true}) {
                        id
                      }
                    }`,
                update: () => {
                  this.mutationLoading = false
                  this.$apollo.queries.vbd.refetch()
                },
              },
            )
          }
        })
    },
  },
  apollo: {
    questiondata: {
      query() {
        return gql`
          query Btns {
            growth_category_startup_entry(where: {id: {_eq: "${this.$route.params.id}"}}) {
              description
              title
              growth_parameters_startup_entries {
                response
                growth_custom_parameter {
                  parameter
                  input_type
                }
                growth_parameter {
                  title
                  input_type
                }
              }
            }
          }`
      },
      update: data => data.growth_category_startup_entry,
    },
  },
}
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
